import React, { Component } from 'react'
import styled from 'styled-components'

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  pointer-events: none;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity ease 200ms;
`

class PageTransition extends Component {
  constructor(props) {
    super(props)
    this.state = {
      render: false,
      imgLoaded: false,
    }
  }

  componentDidMount() {
    this.loadImages()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({ imgLoaded: false, render: false })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true
  }

  componentDidUpdate() {
    if (this.state.imgLoaded === false) {
      this.loadImages()
    }
  }

  loadImages = () => {
    const images = document.images
    let counter = 0
    const self = this

    const incrementCounter = () => {
      counter++
      if (counter === images.length) {
        self.setState({ imgLoaded: true, render: true })
      }
    }

    for (let img of images) {
      if (img.complete) {
        incrementCounter()
      } else {
        img.addEventListener('load', incrementCounter, false)
      }
    }
  }

  render() {
    const { children } = this.props
    const { imgLoaded } = this.state
    return (
      <>
        <Loader active={!imgLoaded} />
        {children}
      </>
    )
  }
}

export default PageTransition

/**
 * Current problem that needs solving
 * Page components get mounted before the Transition components gets mounted
 * So on slow connections you will see the one before you see the page transition
 */