import './src/styles/reset.css'
import './src/styles/fonts.css'
import './src/styles/theme.css'

import React from 'react'
import PageTransition from './src/components/pageTransition'

export const wrapPageElement = ({ element, props }) => {
  return <PageTransition {...props}>{element}</PageTransition>
}
